<div>
    <div class="tranparency">
        <h1>
            Real Estate Science Podcast
        </h1>

    </div>
    <img src="../../assets/img/podcast-8520409.jpg"class="bigImage">
</div>

<div class="content">
    <p>
                Hallo und Herzlich Willkommen zu dem Real Estate Science Podcast der HAWK - Hochschule für Angewandte Wissenschaft und Kunst - Hildesheim, Holzminden, Göttingen. Wir nehmen euch mit in den Forschungsalltag unserer Studierenden im Bereich Immobilienmanagement.

        Wir sprechen über alles rund um das Thema real estate science. Von Immobilienblasen in Japan, über den Demographischen Wandel, bis hin zur Wohneigentumsquote. Und das aus der Sicht angehender Forscherinnen und Forschern.

        Den Studierenden des Masterstudiengangs Immobilienmanagement. Die lesen sich ein in die Literatur, sammeln Daten und beantworten die entscheidenden Fragen.

        Jedes Semester aufs Neue. Die Ergebnisse gibt es dann hier im Real Estate Science Podcast der HAWK.

        Also bleibt dran, schickt uns eure Fragen und hört vor allem rein!
    </p>

    <a href="https://open.spotify.com/" target="_blank">
        <img src="../../assets/img/podcast-4137809.jpg" alt="">
    </a>
    
</div>
    
