import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  constructor(private router:Router){}
  
  
  redirectToMain(){
    this.router.navigateByUrl('')
  }
  redirectToContact(){
    this.router.navigateByUrl('/Kontakt')
  }
  redirectToAboutUs(){
    this.router.navigateByUrl('/Über-uns')
  }
}
