import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(private router:Router){}
  
  
  redirectToMain(){
    this.router.navigateByUrl('')
  }
  redirectToContact(){
    this.router.navigateByUrl('/Kontakt')
  }
  redirectToAboutUs(){
    this.router.navigateByUrl('/Über-uns')
  }
}
